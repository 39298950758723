import PropTypes from 'prop-types';
import React from 'react';

const SEO = ({ title }) => {
  return (
    <>
      <meta charSet="utf-8" />
      <title>
        {title} || PerfekTech - Digital Consulting Agency - Web, Mobile, Design
      </title>
      <meta name="robots" content="noindex, follow" />
      <meta
        name="description"
        content="PerfekTech - Digital Consulting Agency - Web, Mobile, Design"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
