import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop';

// Pages import Here
import Home from './pages/Home';
import Contact from './elements/contact/Contact';
import Brand from './elements/brand/Brand';

import Error from './pages/Error';

// Import Css Here
import './assets/scss/style.scss';

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + '/'}`}
            exact
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/contact'}`}
            exact
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/brand'}`}
            exact
            component={Brand}
          />

          <Route
            path={`${process.env.PUBLIC_URL + '/error'}`}
            exact
            component={Error}
          />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
