import React from 'react';
import Typed from 'react-typed';
import SEO from '../common/SEO';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import HeaderTwo from '../common/header/HeaderTwo';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
import ServiceFour from '../elements/service/ServiceFive';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import TeamTwo from '../elements/team/TeamTwo';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import Separator from '../elements/separator/Separator';
import Mission from '../elements/about/Mission';
import TimelineOne from '../elements/timeline/TimelineOne';
import ServiceOne from '../elements/service/ServiceOne';

const Home = () => {
  return (
    <>
      <SEO title="PerfekTech - Web & Mobile Design - Home" />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  {/* <span className="subtitle">DIGITAL CONSULTING AGENCY</span> */}
                  <h1 className="title theme-gradient display-two">
                    Let Us Help You With <br />{' '}
                    <Typed
                      strings={['Web Design', 'Apps', 'Digital Marketing']}
                      typeSpeed={60}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                    {/* <br />
                    To The Next Level */}
                  </h1>
                  <p className="description">
                    We help our clients succeed by creating high quality web and
                    mobile digital experiences.
                  </p>
                  <div className="button-group">
                    {/* <a
                      className="btn-default btn-medium round btn-icon"
                      target="_blank"
                      href="https://themeforest.net/checkout/from_item/33571911?license=regular"
                      rel="noreferrer"
                    >
                      Purchase Now{' '}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a> */}
                    {/* <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="#"
                    >
                      Contact Us{' '}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        {/* Start About Area  */}
        {/* <AboutOne /> */}
        {/* End About Area  */}

        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What we can do for you"
                  title="Our Services"
                  description="We deliver quality, creativity and simplicity."
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Call To Action Area  */}
        {/* <div className="rwt-callto-action-area">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div> */}
        {/* End Call To Action Area  */}

        <Separator />
        {/* Start Mission Area   */}
        <Mission />
        {/* Start Mission Area  */}

        <Separator />
        <div className="rwt-timeline-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Timeline"
                  title="Working Process."
                  description="We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials."
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1 mt--50">
                <TimelineOne classVar="dark-line" />
              </div>
            </div>
          </div>
        </div>

        <Separator />
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default Home;
